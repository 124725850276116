import { computed, markRaw, reactive, ref, type Raw } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import type { Camera, CameraGroup } from '@/lib/api'
import { useServices } from '@/lib/services'
import { CameraCore } from '../CameraCore'
import { CameraDrawerMode, type CameraTemp } from '../interface'
import { useTabStore } from '@/stores/tab/useTabStore'

interface CameraDrawer {
  camera: Raw<CameraCore | CameraTemp> | undefined
  id: string
  readonly: boolean
  mode: CameraDrawerMode
  callback: Function
}

export const useCameraStore = defineStore('cameraStore', () => {
  const _services = useServices()
  const cameras: Array<CameraCore> = reactive([])
  const cameraGroups: Array<CameraGroup> = reactive([])
  const cameraIds: Array<string> = reactive([])
  const _openDrawer = ref(false)
  const _cameraDrawer = reactive<Partial<CameraDrawer>>({
    camera: undefined,
    readonly: false,
    mode: CameraDrawerMode.profile,
    callback: undefined
  })
  const fetching: undefined | Promise<any> = undefined

  const loading = computed(() => fetching !== undefined)

  const cameraDrawer = computed(() => _cameraDrawer)

  const openDrawer = computed(() => _openDrawer.value)

  const currentCamera = computed({
    get() {
      if (_cameraDrawer.camera) {
        if ('id' in _cameraDrawer.camera || 'cameraData' in _cameraDrawer.camera) {
          return _cameraDrawer.camera as Raw<CameraCore | CameraTemp> | undefined
        } else {
          return undefined
        }
      } else {
        return undefined
      }
    },
    set(newCamera) {
      if (newCamera) {
        // Ensure _cameraDrawer.camera exists and is updated with markRaw(newCamera)
        _cameraDrawer.camera = markRaw(newCamera)
      } else {
        // Clear the camera property when newCamera is undefined
        _cameraDrawer.camera = undefined
      }
    }
  })

  const bridgesCameras = computed(() => {
    const list: CameraCore[] = []
    for (const camera of cameras) {
      if (camera.bridge.bridgeId) {
        list.push(camera)
      }
    }
    return list
  })

  const usableCameras = computed(() => {
    const usables: Array<CameraCore> = []
    for (const cameraId of cameraIdList.value) {
      const camera = getCameraFetched(cameraId)
      if (camera && camera.base.data.configured) {
        usables.push(camera)
      }
    }
    return usables
  })

  const hasUsableCamera = computed(() => usableCameras.value.length > 0)

  const cameraIdList = computed(() => cameraIds)

  const camerasLength = computed(() => cameras.length)

  function pushId(id: string): void {
    if (!cameraIds.find((ci) => ci === id)) {
      cameraIds.push(id)
    }
  }

  function _pushCameraToList(cameraArg: CameraCore | CameraCore[]) {
    function push(camera) {
      const isCameraExists = Boolean(cameras.find((c) => c.id === camera.id))
      if (!isCameraExists) {
        cameras.push(markRaw(camera))
        pushId(camera.id)
      }
    }

    if (Array.isArray(cameraArg)) {
      for (const c of cameraArg) {
        push(c)
      }
    } else {
      push(cameraArg)
    }
  }

  async function findAll(): Promise<Array<Camera>> {
    return await _services.camera.findAll()
  }

  async function getCamera(id: string): Promise<CameraCore | undefined> {
    const camera = getCameraFetched(id)
    if (!camera) await fetchCamera(id)
    return camera
  }

  function getCameraFetched(id: string): CameraCore | undefined {
    const index = cameras.findIndex((c) => c.id === id)
    if (index >= 0) return reactive(cameras[index]) as unknown as CameraCore
    return undefined
  }
  function getCameraFethedName(id: string): string {
    const camera = getCameraFetched(id)
    if (camera) return camera.base.data.name || camera.base.data.id
    else return ''
  }

  async function fetchCameras(
    locked: boolean = false,
    force: boolean = false
  ): Promise<Array<CameraCore>> {
    if (cameras.length === 0 || force) {
      const camerasFetch = await findAll()
      const reqs: Promise<CameraCore>[] = []
      camerasFetch.forEach((camera) => {
        const index = cameras.findIndex((c) => c.id === camera.id)
        if (index === -1) reqs.push(CameraCore.init(camera))
        else {
          cameras[index].base.initialCameraData(camera)
        }
      })
      const initiatedCameras = await Promise.all(reqs)
      initiatedCameras.forEach((c) => c.block(locked))
      _pushCameraToList(initiatedCameras)
    }
    return cameras
  }

  async function fetchCamerasBasic(): Promise<Array<Camera>> {
    const data = await findAll()
    data.forEach((camera) => {
      if (!cameraIds.some((id) => id === camera.id)) pushId(camera.id)
      else {
        const index = cameras.findIndex((c) => c.id === camera.id)
        cameras[index].base.initialCameraData(camera)
      }
    })
    return data
  }

  async function fetchCamera(id: string, locked: boolean = false): Promise<CameraCore> {
    let camera = getCameraFetched(id)
    if (!camera) {
      camera = await CameraCore.init(id)
      if (camera) {
        if (locked) camera.block(locked)
        _pushCameraToList(camera)
      }
    } else {
      await camera.getCameraData()
    }
    if (camera && locked) camera.block(locked)
    return camera
  }

  async function createCamera(cameraTemp: CameraTemp): Promise<CameraCore> {
    const camera = await CameraCore.init(cameraTemp)
    _pushCameraToList(camera)
    return camera
  }

  function removeFromList(id: string) {
    const camera = getCameraFetched(id)
    if (camera) {
      const index = cameras.findIndex((c) => c.id === id)
      if (index !== -1) cameras.splice(index, 1)
      const idIndex = cameraIds.findIndex((cameraId) => cameraId === id)
      if (idIndex !== -1) cameraIds.splice(idIndex, 1)
      camera.status.unregisterFroUpdater()
    }
  }

  async function deleteCamera(id: string, actualDeleted: boolean = false): Promise<string> {
    const camera = getCameraFetched(id)
    if (camera) {
      if (actualDeleted) {
        await camera.delete()
      }
      removeFromList(id)
      const { tabs } = storeToRefs(useTabStore())
      const removeTabItem = (tabs.value as any).find(
        (tab) => tab.route === 'cameraDetail' && tab.settings && tab.settings.value.cameraId == id
      )
      if (removeTabItem) {
        await useTabStore().removeTab(removeTabItem.id, removeTabItem)
      }
      return camera.base.data.name
    }
    return ''
  }

  async function getCameraGroups(force: boolean = false) {
    if (cameraGroups.length === 0 || force) {
      const groups = await _services.cameraGroup.list()
      cameraGroups.splice(0, cameraGroups.length, ...groups)
    }
    return cameraGroups
  }

  function removeBridges(bridgeId: string) {
    const cameraList = cameras.filter((c) => c.bridge.id === bridgeId)
    const idList = cameraList.map((c) => c.id)
    idList.forEach((id) => removeFromList(id))
  }

  function reset() {
    cameras.length = 0
    cameraIds.length = 0
    cameraGroups.length = 0
    resetCameraDrawerObject()
    closeDrawer()
  }

  function closeDrawer() {
    _openDrawer.value = false
  }

  function resetCameraDrawerObject(resetShow: boolean = true) {
    _cameraDrawer.camera = Object()
    _cameraDrawer.readonly = false
    _cameraDrawer.mode = CameraDrawerMode.profile
    if (resetShow) closeDrawer()
  }

  function openCameraDrawer(cameraDrawer: Partial<CameraDrawer>) {
    if (cameraDrawer.id) {
      const camera = getCameraFetched(cameraDrawer.id) as CameraCore
      if (camera) {
        if (camera.locked) return
        currentCamera.value = camera
      } else {
        currentCamera.value = undefined
      }
    } else {
      currentCamera.value = cameraDrawer.camera
    }
    _cameraDrawer.readonly = Boolean(cameraDrawer.readonly)
    _cameraDrawer.mode = cameraDrawer.mode ? cameraDrawer.mode : CameraDrawerMode.profile
    _cameraDrawer.callback = cameraDrawer.callback
    _openDrawer.value = true
  }

  async function closeCameraDrawer() {
    if (cameraDrawer.value.callback) await cameraDrawer.value.callback(currentCamera.value)
    resetCameraDrawerObject()
  }

  function blockCameras(bridgeId: string, locked: boolean) {
    cameras.forEach((c) => {
      if (c.bridge.bridgeId === bridgeId) {
        c.block(locked)
      }
    })
  }

  return {
    cameraGroups,
    bridgesCameras,
    camerasLength,
    cameraIdList,
    cameras: cameras as Array<CameraCore>,
    usableCameras,
    hasUsableCamera,
    currentCamera,
    cameraDrawer,
    openDrawer,
    loading,
    getCamera,
    fetchCameras,
    fetchCamerasBasic,
    getCameraFetched,
    getCameraFethedName,
    fetchCamera,
    createCamera,
    removeFromList,
    deleteCamera,
    getCameraGroups,
    openCameraDrawer,
    closeCameraDrawer,
    reset,
    removeBridges,
    blockCameras
  }
})
