import { defineStore, storeToRefs } from 'pinia'
import type { CreateTabData, TabsResponse } from '@/lib/api'
import { CustomSettingsType } from '@/lib/api'
import { useServices } from '@/lib/services'
import { removedItem, updatedItem } from '@/stores/StoreUtils'
import { useImpexStore } from '@/stores/import-export/useImpexStore'
import { orderBy } from 'lodash'
import router from '@/router'
import { type AuthoritiesFinderOptions, usePermissionManager } from '@/modules/Permissions'
import type { RouteRecordRaw } from 'vue-router'
import { routesObject } from '@/router/routes'
import { useCustomSettingStore } from '../custom-setting/useCustomSettingStore'
import { useRouter } from 'vue-router'
import { useMonitoringTabsWrapper } from '@/modules/Monitoring/helpers/monitoringTabs'
import { Modes } from '@/interfaces'

interface TabState {
  activeTab: TabsResponse | null
  arrayTabs: Array<TabsResponse>
  filteredRoutes: { route: string; key: string; arial: string; name: string }[]
  loading: boolean
  fetchingTabs: undefined | Promise<any>
}

function getAllRoutes(routes: readonly RouteRecordRaw[], result: RouteRecordRaw[]) {
  for (const route of routes) {
    if (route.name) {
      result.push(route)
    }
    if (route.children) {
      getAllRoutes(route.children, result)
    }
  }
  return result
}

export const useTabStore = defineStore('tabStore', {
  state: (): TabState => ({
    activeTab: null,
    arrayTabs: [],
    filteredRoutes: [],
    loading: false,
    fetchingTabs: undefined
  }),

  getters: {
    tabs(): TabsResponse[] {
      return orderBy(this.arrayTabs, 'createdAt')
    }
  },

  actions: {
    startLoading() {
      this.loading = true
    },
    stopLoading() {
      this.loading = false
    },
    async fetchTabs() {
      if (this.fetchingTabs !== undefined) {
        return this.fetchingTabs
      } else {
        this.startLoading()
        const { setTabState } = useImpexStore()
        this.fetchingTabs = useServices()
          .tabs.list()
          .then((tabs) => {
            this.arrayTabs = tabs
            this.fetchingTabs = undefined
            setTabState(this.arrayTabs)
            this.stopLoading()
          })
        return this.fetchingTabs
      }
    },
    async createTab(data: CreateTabData, redirect = true) {
      const response = await useServices().tabs.create(data)
      this.arrayTabs.push(response)
      if (redirect) {
        this.changeActiveTab(response)
      }
      return response
    },
    async UpdateTab(id: string, updateData: CreateTabData) {
      const response = await useServices().tabs.update(id, updateData)
      updatedItem(this.arrayTabs, response)
    },

    async closeOnboaroding() {
      const onboardingTab = this.arrayTabs.find((item) => item.route === 'onboarding')
      await this.redirectToLastMonitoring()
      if (onboardingTab && String(import.meta.env.MODE ?? Modes.main) !== Modes.local) {
        await this.removeTab(onboardingTab.id, onboardingTab, false)
      }
    },

    async redirectToLastMonitoring() {
      let streamingTabs = await useMonitoringTabsWrapper().createMonitoringForAllCameras()
      streamingTabs = streamingTabs.sort(
        (st1, st2) => new Date(st1.createdAt).getDate() - new Date(st2.createdAt).getDate()
      )
      const streamingTabsLength = streamingTabs.length
      if (streamingTabsLength > 0) {
        this.changeActiveTab(streamingTabs[streamingTabsLength - 1])
      }
    },

    async removeTab(id: string, data: TabsResponse, goToFirstOne: boolean = true): Promise<void> {
      await useServices().tabs.remove(id)
      removedItem(this.arrayTabs, data)
      if (goToFirstOne && this.arrayTabs.length !== 0) {
        this.changeActiveTab(this.tabs[0])
      }
      useImpexStore().$reset()
      useImpexStore().setTabState(this.arrayTabs)
      if (this.arrayTabs.length === 0) {
        this.getOrCreateMonitoringTab()
        useRouter().push({
          name: 'selectWorkspace'
        })
      }
    },

    async createOnboardingTab() {
      await this.fetchTabs()
      const onboardingTab = this.arrayTabs.find((item) => item.route === 'onboarding')
      if (!onboardingTab) {
        await Promise.all([
          useCustomSettingStore().getWorkspaceListCustom(),
          useCustomSettingStore().getUserWorkspaceListCustom()
        ])
        const { isOnboardUser } = storeToRefs(useCustomSettingStore())
        const hasPermission = usePermissionManager().hasPermission([
          { action: 'cameraCreate' },
          { action: 'workspaceViewPayment' }
        ])
        if (!isOnboardUser.value && hasPermission) {
          const onboardingTabData: CreateTabData = {
            route: 'onboarding',
            name: 'Onboarding',
            settings: {
              key: 'onboarding',
              value: {
                steps: [],
                completedSteps: [],
                processingSteps: ['workspace'],
                nextStep: 'cameras',
                currentStep: 'workspace',
                data: {
                  camera: {
                    currentStep: 1,
                    cameraList: [],
                    selections: [],
                    process: 0,
                    staticIp: '',
                    verifyConfigureIp: false
                  },
                  payment: {
                    fullName: '',
                    zipCode: ''
                  },
                  cameraUsers: []
                }
              },
              type: CustomSettingsType.userWorkspace
            }
          }
          const adminAccess = usePermissionManager().hasPermission([
            { action: 'workspaceManagePayment' }
          ])
          await this.createTab(onboardingTabData, adminAccess)
        }
      }
    },

    async createCameraConnectTab() {
      const cameraConnectTab = routesObject.find(({ route }) => route === 'cameraConnect')
      if (cameraConnectTab)
        await this.createTab({
          name: cameraConnectTab.name,
          route: cameraConnectTab.route,
          settings: {
            key: cameraConnectTab.route,
            value: {
              steps: [],
              nextStep: 'cameras',
              currentStep: 'pairing',
              data: {
                camera: {
                  currentStep: 1,
                  cameraList: [],
                  selections: [],
                  process: 0,
                  staticIp: '',
                  verifyConfigureIp: false
                },
                payment: {
                  fullName: '',
                  zipCode: ''
                },
                cameraUsers: []
              }
            },
            type: CustomSettingsType.workspace
          }
        })
    },

    changeActiveTab(data: TabsResponse) {
      const actions = router.getRoutes().find((route) => route.name === data.route)?.meta?.actions
      if (
        !actions ||
        (actions &&
          usePermissionManager().userPermissions.checkAllPermissions(
            actions as AuthoritiesFinderOptions[]
          ))
      ) {
        if (
          !this.activeTab ||
          this.activeTab.route !== data.route ||
          ((this.activeTab.route === 'streaming' ||
            this.activeTab.route === 'streamingPlayback' ||
            this.activeTab.route === 'streamingHeatmap') &&
            this.activeTab.settings.id !== data.settings.id) ||
          (this.activeTab.route === 'cameraDetail' &&
            this.activeTab.settings.id !== data.settings.id)
        ) {
          localStorage.setItem('activeTab', String(data.id))
          this.activeTab = data
        }
      }
    },

    async getOrCreateMonitoringTab() {
      let monitoringTab = this.arrayTabs.find((item) => item.route === 'streaming')
      if (!monitoringTab) {
        const key = useMonitoringTabsWrapper().createMonitoringTab()
        monitoringTab = await this.createTab({
          route: 'streaming',
          name: 'Monitoring',
          settings: {
            type: CustomSettingsType.userWorkspace,
            key: 'streaming',
            value: {
              tabId: key.params.tabId
            }
          }
        })
      }
      return monitoringTab
    },

    async checkForMonitoringTabToBeExists() {
      const waiting = this.fetchingTabs
      if (waiting !== undefined) await waiting
      const monitoringTab = await this.getOrCreateMonitoringTab()
      setTimeout(() => {
        this.changeActiveTab(monitoringTab)
        router.push({
          name: 'streaming',
          params: {
            tabId: monitoringTab.settings.value.tabId
          }
        })
      })
    },

    async checkTabsPermission() {
      const routes = router.getRoutes()
      const routesFlat = getAllRoutes(routes, [])
      const promisses = this.arrayTabs.map(async (tab) => {
        const selectedRoute = routesFlat.find((r) => r.name === tab.route)
        if (selectedRoute && 'meta' in selectedRoute && selectedRoute?.meta?.actions) {
          const authority: AuthoritiesFinderOptions[] = selectedRoute?.meta
            ?.actions as unknown as AuthoritiesFinderOptions[]
          return !usePermissionManager().hasPermission(authority)
            ? await this.removeTab(tab.id, tab)
            : ''
        }
        return ''
      })
      await Promise.all(promisses)
      await this.filterRoutes()
    },

    async filterRoutes(attempt: number = 0) {
      const routes = router.getRoutes()
      if (routes.length > 0 && routesObject) {
        this.filteredRoutes = routesObject.filter(({ route: objectRoute }) => {
          const route = routes.find((route) => {
            return route.name === objectRoute
          })
          if (route && route.meta && route.meta.actions) {
            return usePermissionManager().hasPermission(
              route.meta.actions as AuthoritiesFinderOptions[]
            )
          } else {
            return true
          }
        })
      } else if (attempt < 2) {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        await this.filterRoutes(attempt + 1)
      }
    }
  }
})
