import * as Sentry from '@sentry/vue'
// import styles
import '../public/themes/dark.css'
import 'primevue/resources/primevue.min.css'
import '../src/assets/themes/style.scss'
import '../src/index.css'
import './player/assets/style.scss'
import 'primevue/resources/themes/lara-dark-teal/theme.css'
// import infrastructure
import { createApp } from 'vue'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
// imports libs
import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'

// import application
import App from './App.vue'
import './utils/validation/Validations'
// import as directive
import BadgeDirective from 'primevue/badgedirective'
import Validation from './modules/forms/directive'

// Import modules required be to globally
import { usePermissionManager } from './modules/Permissions/'
import { i18n } from './modules/i18n'
import { useTabManager } from '@/modules/tab/lib/useTabManager'
import router from '@/router'
import { Modes } from './interfaces'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)

const permissionManager = usePermissionManager()
permissionManager.setup(app)

const sentryDSN = import.meta.env.VITE_SENTRY_DSN

if (sentryDSN && String(import.meta.env.MODE ?? Modes.main) !== Modes.local) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', new RegExp(import.meta.env.VITE_SENTRY_TARGET)],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const tabManager = useTabManager()

app.use(pinia)
app.use(router)
app.use(i18n)
app.use(PrimeVue)
app.use(ToastService)
app.use(ConfirmationService)
app.directive('tooltip', Tooltip)
app.directive('badge', BadgeDirective)
app.directive('v-vlidate', Validation)
app.mount('#app')
